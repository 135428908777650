<template>
  <b-card class="text h-auto">
    <h1 class="font-weight-bolder text-xl-center head-text">
      Términos y condiciones
    </h1>
    <hr>
    <h3 class="font-weight-bolder text-left px-2">
      Todo lo que necesitas saber para continuar
    </h3>

    <b-card-text class="text-dark text-lg-left mt-1 px-2">
      Hemos actualizado nuestros términos y condiciones, los cuales puedes
      consultar a continuación. Al hacer clic abajo, aceptas los nuevos
      términos y condiciones.
    </b-card-text>
    <b-card-text class="text-dark text-lg-left mt-2 px-2">
      Estos incluyen los siguientes cambios:
    </b-card-text>
    <ul class="px-3">
      <li class="">
        Aceptas que todos los pagos sean por medio de la plataforma
      </li>
      <li class="">
        En BrandMe nos comprometemos a la seguridad de tus datos
      </li>
    </ul>
    <b-card-text class="text-dark text-lg-left mt-2 px-2">
      Si no aceptas los nuevos términos y condiciones,
      <a class="link" @click="deleteAccount()"
        >haz clic aquí para eliminar tu cuenta.</a
      >
    </b-card-text>

    <b-card
      bg-variant="light"
      class="text-center overflow-scroll card p-1 mt-3 m-2 card-terms"
    >
      <b-card-title class="font-weight-bolder text-header text-left"
        >Última modificación: 12 de Enero del 2023</b-card-title
      >
      <h2 class="font-weight-bolder text-header subheader text-left">
        Términos y condiciones:
      </h2>
      <p class="text-justify"><strong>1.- Información General</strong></p>
      <p class="text-justify">
        El acceso y uso del sitio
        <a
          class="color-purple"
          href="https://app.brandme.la/"
          target="_blank"
          rel="noopener noreferrer"
          >www.brandme.la</a
        >
        – en adelante el SITIO &#8211; es ofrecido por la compañía de
        nacionalidad mexicana ‘BRANDME CROWDMARKETING, S.A.P.I. de C.V.’ con
        RFC BCR130924V68, inscrita en el Registro Público de la Propiedad y
        de Comercio con el Folio Mercantil Ciento seis mil quinientos
        cuarenta y tres, con fecha del 20 de Septiembre del 2013, con
        domicilio en Calle Tonalá 10, Colonia Roma Norte, Delegación
        Cuauhtémoc, Ciudad de México, CP06700, México
      </p>
      <p class="text-justify">
        Para cualquier cuestión o duda que esté relacionada con el acceso y
        uso del SITIO o de los presentes Términos y condiciones de Uso del
        Sitio (en adelante “TCUS”) puede dirigir un correo electrónico con
        su solicitud y datos de contacto a la siguiente dirección:
        <a
          class="color-purple"
          href="mailto:contacto@brandme.la"
          target="_blank"
          rel="noopener noreferrer"
          >contacto@brandme.la</a
        >. Además, puede contactar con nosotros dirigiendo su solicitud y
        datos de contacto a BRANDME en su domicilio antes señalado.
      </p>
      <p class="text-justify">
        Sin perjuicio de ello, BRANDME podrá transmitir notificaciones a
        través de la página de inicio de la página Web, a fin de informar al
        Usuario acerca de los cambios introducidos en el SITIO. Dichas
        notificaciones serán consideradas como válidas y por ende, como
        notificaciones al Usuario.
      </p>
      <p class="text-justify">
        <strong
          >2.- Aceptación de los presentes Términos y condiciones para el
          Uso del Sitio.</strong
        >
      </p>
      <p class="text-justify">
        2.1. &#8211; El acceso y navegación por el SITIO implica
        automáticamente la aceptación tácita de los presentes TCUS, por lo
        que las personas que accedan y naveguen por el SITIO se obligan a
        cumplirlas y respetarlas bajo su exclusiva responsabilidad.
      </p>
      <p class="text-justify">
        2.2. &#8211; A fin de obtener la mejor efectividad del SITIO,
        BRANDME podrá denegar o restringir el acceso a Usuarios que no
        respeten los presentes TCUS sin necesidad de previa notificación.
      </p>
      <p class="text-justify">
        2.3. &#8211; Los presentes TCUS podrán ser modificadas por BRANDME
        en cualquier momento sin previo aviso, cuando se considere
        conveniente, oportuno o necesario para optimizar y/o adecuar la
        regulación del acceso y uso del SITIO. Una vez introducida la
        referida modificación, si el Usuario sigue accediendo y utilizando
        las distintas herramientas del SITIO se entenderá que acepta dichas
        modificaciones, de lo contrario deberá abstenerse de su utilización.
      </p>
      <p class="text-justify">
        2.4. &#8211; Sin perjuicio de las presentes TCUS, BRANDME podrá
        establecer determinadas condiciones particulares, que serán de
        obligado cumplimiento para el acceso y uso de algunos de los
        servicios que ofrece el SITIO. Por otra parte, podrán existir
        condiciones especiales para algunos Usuarios que aplique solamente
        para ellos, a efecto de claridad, se definirán aquellos supuestos
        que sean de aplicación exclusiva para algún tipo de Usuarios. Además
        de las presentes TCUS, para el acceso y uso del SITIO, deberá
        respetarse el Aviso de Privacidad y Protección de Datos que se
        indica en las presentes condiciones generales.
      </p>
      <p class="text-justify">
        2.5. &#8211; A los efectos de las presentes TCUS, se entenderá como
        Usuario cualquier persona que acceda y navegue por el SITIO, con
        independencia del tipo de Usuario de que se trate, o de si se
        registra cumplimentando los formularios de registro y/o utiliza
        cualquiera de los servicios que éste ofrece, sea gratuito o sujeto
        al pago de una cantidad determinada.
      </p>
      <p class="text-justify">
        <strong
          >3.- Descripción del SITIO y EL SISTEMA y finalidad de uso</strong
        >
      </p>
      <p class="text-justify">
        3.1. – BRANDME le provee a usted acceso a su plataforma en línea (EL
        SISTEMA), ubicada en el SITIO www.brandme.la, por medio de la cual
        interactúan varios tipos de Usuarios con miras a lograr crear y
        difundir Campañas en línea a través de Redes Sociales. Para
        comprender las relaciones que se generan a través del SITIO, y para
        definir los alcances y obligaciones para cada tipo de Usuario, se
        definen a continuación los diferentes Usuario que puede acceder al
        SITIO, así como algunos conceptos centrales para comprender la
        operación de BRANDME:
      </p>
      <ol class="lower-alpha pl-default ml-1">
        <li class="pb-lg text-left">
          Usuario.- Cualquier persona física o moral que ingrese con o sin
          registro al SITIO.
        </li>
        <li class="pb-lg text-left">
          Anunciantes.- Usuarios que buscan contratar los servicios de
          Creadores para campañas publicitarias a través del SITIO, quienes
          podrán realizar depósito de Fondos en la Plataforma de BRANDME (EL
          SISTEMA).
        </li>
        <li class="pb-lg text-left">
          Creadores.- Todo Usuario de Redes Sociales que se registre en el
          SITIO con esta calidad, y que sea usuario de alguna Red Social y
          quien recibirá compensación por crear contenido o enviar contenido
          a través de la Plataforma.
        </li>
        <li class="pb-lg text-left">
          Contratante.- Cualquier Usuario sin la calidad de Anunciante que
          contrate los servicios de Creadores a través de EL SISTEMA, pero
          el cual para efectos de estos TCUS recibirá el tratamiento de
          Anunciante, por lo que cualquier referencia a “el Anunciante”
          dentro de los TCUS, deberá considerarse que también aplican para
          los Contrantes.
        </li>
        <li class="pb-lg text-left">
          Red Social.- Programas de Computo que funcionan a través de la red
          mundial conocida como Internet a través de los cuales, las
          personas crean cuentas y comparten información con otros usuarios
          de estas redes. Se identifican a continuación algunas de las
          principales redes sociales que operan actualmente, pero se
          entenderá por medio de estos TCUS que estos serán aplicables para
          estas redes sociales y cualquier otra que exista en el presente o
          en el futuro.
        </li>
        <li class="pb-lg text-left">
          Twitter.- Red Social de microblogging donde sus usuarios comparten
          ideas de hasta 140 caracteres con otros usuarios de dicha
          plataforma.
        </li>
        <li class="pb-lg text-left">
          Facebook.- Red Social donde las personas crean perfiles y
          comparten fotos, videos, escritos e incluso pueden participar en
          juegos en línea e interactivos con otros.
        </li>
        <li class="pb-lg text-left">
          Instagram.- Red social especializada en fotografías y videos,
          donde además las partes pueden comentar publicaciones.
        </li>
        <li class="pb-lg text-left">
          Recompensa.- El pago que realiza algún Usuario por los servicios
          de otro a través de EL SISTEMA
        </li>
        <li class="pb-lg text-left">
          Calidad VIP.- Calidad otorgada a algún Usuario dentro del SITIO,
          por el cual podrá obtener mayores Recompensas o acceder a
          privilegios especiales otorgados por BRANDME.
        </li>
        <li class="pb-lg text-left">
          Campaña Publicitaria (La Campaña).- Se refiere a cualquier
          proyecto de promoción de algún producto o servicio que se gestione
          a través de EL SISTEMA.
        </li>
        <li class="pb-lg text-left">
          Trending Topic (TT).- Dentro de alguna Red Social cuando un tema
          se convierte en un tema de amplia difusión generando muchos
          mensajes, se considera un tema de discusión masiva, o TT.
        </li>
        <li class="pb-lg text-left">
          Hashtag (#).- Cualquier término utilizado en Redes Sociales
          precedido por el símbolo # que hace referencia a un tema de
          dialogo en esa Red.
        </li>
        <li class="pb-lg text-left">
          Interfaz de Programación de Aplicación (API).- Conjunto de
          subrutinas, funciones y procedimientos que constituyen la
          estructura de EL SISTEMA.
        </li>
      </ol>
      <p class="text-justify">
        Con base en lo anterior, se deben entender que la funcionalidad que
        otorga BRANDME a través de EL SISTEMA consiste en permitir a los
        Contratantes o Anunciantes contactar y recompensar a los Creadores
        que cuentan con una cuenta en redes sociales como Twitter, Facebook,
        Instagram, entre otras, a través de la cual crean o distribuyen
        contenido único (el “Servicio”) para ayudarlos a promover algún
        producto o servicio. Debe entenderse en todo momento que la firma de
        estos TCUS no somete ni obliga a ninguna parte a tener que unirse o
        a patrocinar una determinada campaña publicitaria. Todos los
        Usuarios conservan su libre albedrío a efecto de decidir el tipo de
        campañas que crean o si se unen a una campaña publicitaria en
        marcha.
      </p>
      <p class="text-justify">
        Si usted no está de acuerdo con estos TCUS, no utilice el Servicio.
        El acceso, navegación y uso del SITIO constituye una aceptación
        tácita de estos TCUS por el Usuario. BRANDME se reserva el derecho a
        modificar los TCUS en cualquier omoento, bastando para ello la
        publicación en su SITIO, y para facilidad del Usuario, BRANDME
        colocará en la parte inferior derecha de la página de inicio del
        SITIO, la versión más reciente de estos TCUS, y se considerarán
        aceptados en su última versión, con el uso continuado del SITIO. La
        sumisión y vigencia del Usuario a estos TCUS se considerará que
        comienzan con la primera navegación sin registro por el SITIO y con
        el registro en el mismo como Usuario (en su caso). Será necesario
        notificar por escrito a BRANDME la intención del Usuario a dar por
        terminada su relación con BRANDME, en caso de que EL SISTEMA lo
        permita, el aviso de terminación podrá también notificarse por ese
        medio.
      </p>
      <p class="text-justify">
        3.2. – BRANDME y EL SISTEMA no realiza ninguna actividad de
        intermediación laboral ni de agente intermediario con ninguno de los
        Usuarios del SITIO, ni es parte de cualquiera de ningún contrato de
        prestación de servicios que los Usuarios puedan suscribir entre
        ellos. En ningún caso, BRANDME será responsable por el uso que los
        Usuarios den a EL SISTEMA, lo anterior se refiere a que bajo ninguna
        circunstancia se considerará que BRANDME apoya o esta de acuerdo con
        el sentido de alguna campaña publicitaria o con alguna opinión o
        mensaje que fijen los Usuarios en Internet o por cualquier otro
        medio de comunicación. BRANDME conserva en todo momento una postura
        de imparcialidad y neutralidad ante cualquier actividad que realicen
        a través de EL SISTEMA los Usuarios, y por tanto, en caso de que
        algún Usuario genere algún problema de imagen o que relacione a
        BRANDME a sus ideas o propuestas que impacten a BRANDME
        negativamente, así como en caso de que exista cualquier reclamación
        o acción judicial o extrajudicial en contra de BRANDME por acciones
        de algún Usuario, ese Usuario será responsable en todo momento de
        sanear la imagen y el prestigio de BRANDME y de sacarlo a paz y a
        salvo de cualquier reclamación en su contra. Además el Usuario será
        responsable por el pago de los daños y perjuicios que sus acciones
        generen en perjuicio de BRANDME.
      </p>
      <p class="text-justify">
        La presencia de ofertas en el SITIO para que Creadores su unan a
        Campañas Publicitarias, deberá en todo momento entenderse como un
        servicio que ofrece BRANDME en su calidad de Comisionista, y que por
        tanto, no representa de ninguna forma que BRANDME contrate o
        subcontrate a los Usuarios para los fines que acuerden entre
        Usuarios.
      </p>
      <p class="text-justify">
        BRANDME no será responsable del tipo de mensajes que se generen en
        las Redes Sociales respecto a alguna Campaña, lo anterior y
        adicional al sentido de los mensajes que generen los Anunciantes o
        los Creadores, ya que cuando un tema se convierte en un “Trending
        Topic (TT)” personas ajenas a la Campaña pueden utilizar los
        Hashtags para unirse al dialogo, pudiendo ir en sentido opuesto a lo
        que se busca con la Campaña, o incluso afectándola por estos
        mensajes, por lo que los Usuarios entienden que BRANDME no será
        responsable en ningún caso por el tipo de mensajes u opiniones que
        se generen por terceros ajenos a BRANDME en las Campañas.
      </p>
      <p class="text-justify">
        3.3. – A fin de facilitar la contratación de los servicios
        ofrecidos, el SITIO pone a disposición de los Usuarios del mismo un
        conjunto de herramientas informáticas que permiten, entre otros, el
        acceso a una gran variedad de ofertas de servicios y de proyectos,
        la selección de ofertas y proyectos en distintos ámbitos según
        múltiples parámetros de búsqueda, que coadyuven a los Usuarios a
        ponerse en contacto entre ellos para negociar las condiciones del
        Servicio.
      </p>
      <p class="text-justify">
        3.4. – Los Anunciantes participantes crearán y listarán sus propias
        campañas publicitarias en EL SISTEMA ubicado en el SITIO
        www.brandme.la, mediante la cual ofrecen a los Creadores la
        oportunidad de recibir compensación a cambio de crear contenido
        online en distintas redes sociales que cumplan con los
        requerimientos establecidos en la Campaña. Cada Campaña deberá
        definir a través de EL SISTEMA las instrucciones deseadas
        (“Requerimientos”) y la compensación a ser pagada al Creador por su
        participación a través de EL SISTEMA de BRANDME. Los Anunciantes son
        responsables del contenido y de los Requerimientos. La falta de
        datos para iniciar una Campaña o la falta de Fondos para pagar las
        compensaciones respectivas, inhibirán el inicio de la operación de
        la Campaña, sin responsabilidad en ningún caso para BRANDME.
      </p>
      <p class="text-justify">
        3.5. – Transparencia y divulgación.- A pesar de que BRANDME no
        requiere que los Creadores revelen a su audiencia que existe una
        relación comercial entre el Anunciante y el Creador creada por medio
        de BRANDME a través de EL SISTEMA, los Creadores tienen el derecho
        de indicar en su contenido online, si así lo desean, que dicha
        relación existe. Cualquier intento de instruir, coercer o manipular
        al Creador para que oculte la relación comercial entre el
        Anunciante, BRANDME, y el Creador puede resultar en su remoción de
        EL SISTEMA y de la cancelación de la Campaña, así como la retención
        de fondos disponibles como compensación para BRANDME y en su caso
        para otros Usuarios afectados.
      </p>
      <p class="text-justify">
        Todos los Usuarios, Contratantes, Anunciantes e Creadores, aceptan
        cumplir con el Códigos de Ética del Usuario de BRANDME, así como
        cualquier código de conducta del Anunciante y del Creador que llegue
        a crear BRANDME, así como por lo que exijan las leyes mexicanas, y
        de cualquier otra nacionalidad donde opere EL SISTEMA.
      </p>
      <p class="text-justify">
        3.6. – Los Anunciantes y los Creadores aceptan y se obligan a
        cumplir con las siguientes condiciones relativas las Campañas:
      </p>
      <ol class="lower-alpha pl-default ml-1">
        <li class="pb-lg text-left">
          Las Campañas no estarán disponibles de forma permanente y podrán
          limitarse u ofrecerse a un número limitado de Creadores, a
          discreción del Anunciante.
        </li>
        <li class="pb-lg text-left">
          Las Campañas deberán presentarse apropiadamente. El contenido de
          publicaciones inapropiadas serán removidos de www.brandme.la y los
          Creadores responsables serán dados de baja inmediatamente y de
          forma permanente de la Plataforma. Pudiendo BRANDME proceder en
          contra de dicho Usuario por los daños y perjuicios que genere, e
          inclusive autoriza el Creador a BRANDME a retener cualquier monto
          de Recompensas que tenga en EL SISTEMA, a efecto de que se
          compense el daño causado, lo anterior con independencia de las
          acciones legales que decida interponer BRANDME.
        </li>
        <li class="pb-lg text-left">
          Las Campañas no podrán presentarse bajo ninguna forma que:
          <ul class="pt-lg ml-2">
            <li class="pb-lg">
              Promueva o contenga material sexual explícito;
            </li>
            <li class="pb-lg">Promueva el uso de drogas ilegales;</li>
            <li class="pb-lg">
              Promueva la violencia o contenga material violento;
            </li>
            <li class="pb-lg">
              Promueva o contenga material difamatorio o calumnioso;
            </li>
            <li class="pb-lg">
              Promueva la discriminación o emplee prácticas o términos
              discriminatorios basados en raza, sexo, religión,
              nacionalidad, incapacidad, orientación sexual, edad o
              cualquier otro;
            </li>
            <li class="pb-lg">
              Promueva o implique actividades ilícitas o ilegales;
            </li>
            <li class="pb-lg">
              Incluya cualquier marca de BRANDME o sus afiliados; o
            </li>
            <li class="pb-lg">
              De cualquier manera viole derechos de propiedad intelectual de
              terceros.
            </li>
          </ul>
        </li>
        <li class="pb-lg text-left">
          Las publicaciones de los Creadores deberán ser siempre en idioma
          ESPAÑOL, o en el idioma que en su caso se especifique en la
          Campaña respectiva, y los Creadores deberán utilizar sus propias
          palabras y crear contenido original, salvo que se especifique lo
          contrario en la Campaña o en el caso de que el Anunciante
          proporcione mensajes predefinidos al configurar la Campaña,
          siempre y cuando el Creador este dispuesto a unirse a estos
          mensajes predefinidos.
        </li>
      </ol>
      <p class="text-justify">
        3.7. – Los Creadores podrán rechazar la inserción de publicidad de
        un determinado Anunciante, servicio o producto propuesto por el
        Anunciante, sin que ello suponga incumplimiento de estos TCUS.
      </p>
      <p class="text-justify">
        De igual manera, las instrucciones para la creación del contenido
        serán propuestos por los Anunciantes, debiendo los Creadores, dar su
        consentimiento expreso o su rechazo con anterioridad a la
        publicación en su cuenta de Twitter y/o Facebook y/o alguna otra red
        social.
      </p>
      <p class="text-justify">
        Los Anunciantes y los Creadores, serán los únicos responsables del
        contenido de los textos que se publiquen y se comprometen a que
        dichos textos no incluyan contenidos contrarios a los derechos de
        terceros, a las leyes, la moral o al orden público, y que las
        Campañas publicitarias se ajusten a los presentes TCUS, a los
        Requerimientos y a la normativa relativa a la publicidad, servicios
        de la sociedad de la información y el comercio electrónico,
        consumidores y usuarios, derecho de la competencia, así como a
        cualquier otra normativa que pudiera ser aplicable. En ningún caso,
        esta responsabilidad será de BRANDME.
      </p>
      <p class="text-justify">
        3.8. – Revisión de Campañas.- BRANDME colaborará en la revisión e
        identificación del contenido que generen los Creadores, a efecto de
        coadyuvar y garantizar que se cumpla con los TCUS y con los
        Requerimientos de cada Campaña; podrá actuar como Mediador, previa
        solicitud de los Usuarios en pugna, para ayudarlos a resolver
        posibles disputas que se susciten dentro de las Campañas. Sin
        embargo la responsabilidad final en la aprobación de contenidos
        siempre será de los Anunciantes, a su discreción, quienes deberán
        determinar si un contenido en particular debe publicarse. En caso de
        que se de esta autorización, el Anunciante instruye a BRANDME para
        que realice la compensación correspondiente al Creador. Los
        contenidos de los Creadores que no cumplan con la ley, la moral, las
        buenas costumbres, estos TCUS, los Requerimientos de la Campaña o
        que no hayan sido autorizados por los Anunciantes no recibirán la
        compensación asociada con dicha Campaña.
      </p>
      <p class="text-justify">
        3.9. – El Anunciante será en todo momento el responsable de revisar
        que el contenido de las publicaciones sea acorde a los
        Requerimientos indicados al momento de configurar la Campaña. El
        Anunciante se hace responsable del contenido si indica, al
        configurar la Campaña, que no desea revisar el contenido de los
        Creadores de forma previa a su publicación.
      </p>
      <p class="text-justify">
        El Anunciante podrá, a través de EL SISTEMA, solicitar a los
        Creadores cambios en la redacción del contenido, siempre y cuando
        observe nuestros Códigos de Ética, y que dichos cambios se apeguen a
        los Requerimientos previamente establecidos por el Anunciante. El
        Anunciante podrá solicitar únicamente 2 cambios en la redacción del
        contenido a un mismo Creador en una misma Campaña. Si en el tercer
        intento de redacción, por parte del Creador, el Anunciante no está
        de acuerdo con el contenido creado, podrá retirar la participación
        de dicho Creador en esa Campaña específica. El Anunciante, bajo
        ninguna circunstancia, podrá limitar la participación de un Creador,
        previamente invitado a participar en la Campaña, por motivos
        raciales, de género, apariencia física, ideología política o
        espiritual, edad, género u orientación sexual. En el caso de que el
        Anunciante, después de solicitar 2 cambios de redacción del
        contenido, considere que el Creador viola estos TCUS, incumple los
        Requerimientos establecidos al configurar la Campaña o, a propósito
        obstaculiza la continuidad de la campaña, podrá reportar al Creador
        con BRANDME a través del mecanismo pertinente disponible en EL
        SISTEMA.
      </p>
      <p class="text-justify">
        3.10. – El Anunciante acuerda que BRANDME no es responsable en
        ningún aspecto del contenido o producto a ser referido en el
        contenido de las publicaciones de los Creadores contratados para
        alguna Campaña. El Anunciante declara, garantiza y acuerda que:
      </p>
      <ol class="lower-roman pl-default ml-1">
        <li class="pb-lg text-left">
          ) Toda la información provista o aprobada por el Anunciante, o en
          su representación, en relación a estos TCUS y en EL SISTEMA será
          actualizada para ser mantenida actual y precisa;
        </li>
        <li class="pb-lg text-left">
          ) El Sitio al cual referirán los hipervínculos a ser incluidos en
          las publicaciones deberá mostrarse substancialmente igual para
          todos los usuarios finales sin que influya su ubicación
          geográfica;
        </li>
        <li class="pb-lg text-left">
          ) Su sitio web no contiene ningún contenido licenciado por
          BRANDME, a menos que exista un acuerdo firmado con BRANDME.
        </li>
      </ol>
      <p class="text-justify">
        El Anunciante reconoce que los Creadores de BRANDME son terceras
        personas independientes y no controladas por BRANDME. Como
        consecuencia, cualquier publicación que haga referencia a su
        contenido o producto(s) conllevará un riesgo negativo o posibles
        comentarios negativos acerca de su contenido, producto(s) u
        organización. El Anunciante específicamente reconoce que BRANDME no
        tiene ningún control sobre las publicaciones redactadas o
        publicadas, y que solamente el Anunciante es responsable (y asume
        todos los riesgos y responsabilidades) de determinar si el contenido
        de las publicaciones son apropiados y aceptables.
      </p>
      <p class="text-justify">
        3.11. – Sin perjuicio de lo anterior, BRANDME quedará facultada para
        modificar en cualquier momento y sin previo aviso el diseño, imagen,
        presentación y configuración o funcionamiento, así como el contenido
        del SITIO y las funcionalidades incluidas en éste, eliminando,
        modificando, añadiendo nuevas o sujetándolas a condiciones
        específicas, sin que ello suponga alteración de los acuerdos o de la
        relación comercial entre BRANDME y el Usuario.
      </p>
      <p class="text-justify">
        3.12. – Para poder acceder al servicio deberá contarse
        necesariamente con un equipo informático, con los programas y
        configuraciones necesarias para el adecuado funcionamiento del SITIO
        y con conexión a Internet. Los gastos relativos a la conexión serán
        por cuenta exclusiva del Usuario.
      </p>
      <p class="text-justify">
        <strong
          >4.- Registro: Obtención del código de usuario y
          contraseña</strong
        >
      </p>
      <p class="text-justify">
        4.1. – Para proceder a utilizar las funcionalidades y obtener la
        calidad de Usuario dentro de EL SISTEMA, será necesario ser mayor de
        edad y contar con la capacidad jurídica para obligarse en nombre de
        el Usuario. Adicionalmente deberá el Usuario realizar el registro
        siguiendo las instrucciones que para ello existen en el SITIO,
        accesibles a través de la opción “Registro”. En dicha opción el
        Usuario deberá definir si se registra como Anunciante o como
        Creador, y además de aceptar estos TCUS, deberá presentar toda la
        documentación, y firmar cualquier otro acuerdo o documento que para
        otorgar el Registro establezca BRANDME a través de EL SISTEMA y EL
        SITIO.
      </p>
      <p class="text-justify">
        4.2. – El Usuario garantiza bajo su propia responsabilidad la
        exactitud de la información proporcionada, que deberá mantenerse
        actualizada en todo momento, eximiendo expresamente a BRANDME de las
        consecuencias y daños que la inexactitud de la misma pueda originar
        a terceros.
      </p>
      <p class="text-justify">
        4.3. – Una vez cumplimentados todos los campos obligatorios, el
        Usuario acepta y reconoce que entra en un contrato con BRANDME a
        través de este procedimiento de registro en línea, al marcar la
        casilla de aceptación de estos TCUS en el registro, y al dar su
        aceptación por medio de Click-Wrap, esto es, aceptando el proceso de
        Registro con la casilla de los TCUS marcada. BRANDME podrá además
        enviar un enlace de activación por correo electrónico como
        verificación, o en caso de ser requerido por BRANDME, la firma de
        acuerdos y contratos adicionales, sean estos electrónicamente
        aceptados o firmados de manera autógrafa, todo lo anterior para que
        se liberen los códigos de acceso a EL SISTEMA por BRANDME. En todo
        momento el Usuario acepta y reconoce que deberá satisfacer todos los
        requisitos que imponga BRANDME para tener derecho de acceso a EL
        SISTEMA.
      </p>
      <p class="text-justify">
        4.4. – El código de identificación de Usuario y la clave de acceso
        son totalmente personales e intransferibles, asignados única y
        expresamente para el Usuario, responsabilizándose el mismo frente a
        BRANDME y a terceros de la correcta utilización y de la no
        transmisión ni divulgación a terceras personas físicas y/o jurídicas
        que no sean dicho Usuario o persona expresamente facultada por él
        para contratar en su nombre.
      </p>
      <p class="text-justify">
        4.5. – En el caso de cualquier pérdida o robo de las claves de
        acceso, uso no autorizado del SITIO o de los códigos, así como de
        cualquier violación de seguridad de la misma de la que haya tenido
        conocimiento el Usuario, éste se obliga a notificar dicha incidencia
        a BRANDME de inmediato. En este caso, el Usuario tendrá derecho a
        que se le dé otro código de identificación y otra clave para poder
        acceder al SITIO. La falta de este aviso a BRANDME la libera de
        cualquier responsabilidad por el mal uso que se puede dar de las
        claves de acceso a EL SISTEMA. Por otro lado, la negligencia en el
        cuidado de sus credenciales de acceso a EL SISTEMA por parte de el
        Usuario, que devengan en el uso no autorizado de las mismas y que da
        alguna manera causen daños, perjuicios o problemas legales a BRANDME
        u otros Usuarios, obliga a dicho Usuario a compensar los mismos. Por
        tanto los Usuarios instruyen en este acto a BRANDME a utilizar los
        fondos que tengan en EL SISTEMA o las Recompensas por cobrar para
        pagar cualquier daño que devenga del mal uso de sus credenciales de
        acceso a EL SISTEMA.
      </p>
      <p class="text-justify">
        4.6. – El SISTEMA no asume la obligación ni dispone de los medios
        técnicos para comprobar la identidad de quienes se registran en el
        mismo. Por ello, el SITIO no será responsable en caso de usurpación
        de la identidad del Usuario.
      </p>
      <p class="text-justify">
        4.7. – BRANDME se reserva el derecho a rechazar cualquier solicitud
        de registro cuyo contenido no se ajuste a las finalidades del SITIO.
      </p>
      <p class="text-justify">4.8. – Registro de los Anunciantes:</p>
      <ol class="lower-alpha pl-default ml-1">
        <li class="pb-lg text-left">
          Elegibilidad de los Anunciantes.-
          <ol class="lower-roman pt-lg pl-default ml-2">
            <li class="pb-lg">
              ) Podrán registrarse como Anunciantes en la Plataforma de
              BRANDME, de forma enunciativa más no limitativa: Sociedades
              mercantiles; sociedades civiles; asociaciones; empresas
              paraestatales; instituciones gubernamentales; organizaciones
              no lucrativas; agencias de publicidad; agencias de medios y
              particulares.
            </li>
            <li class="pb-lg">
              ) Queda estrictamente prohibido el registro a instituciones
              religiosas, partidos políticos y a organizaciones delictivas.
              Cualquier intento de registro de alguno de estos entes, así
              sea con información falsa, libera a BRANDME de cualquier
              obligación de servicio, y podrá cancelar la cuenta respectiva
              en el momento que se detecte ya sea la información falsa o la
              actividad restringida en este apartado.
            </li>
            <li class="pb-lg">
              ) Queda estrictamente prohibido a los usuarios registrados
              como Anunciantes el utilizar en su nombre de usuario, o en
              cualquier otro campo, nombres, marcas o material protegido por
              las leyes de propiedad industrial o de derechos de autor
              mexicanas o extranjeras que no sean de su propiedad. BRANDME
              se reserva en todo momento el derecho de eliminar o suspender,
              a su discreción, la cuenta de cualquier Anunciante que
              infrinja este supuesto, cualquier ley aplicable, estos TCUS,
              la moral o las buenas costumbres.
            </li>
            <li class="pb-lg">
              ) Los Anunciantes podrán contratar, bien directamente o bien a
              través de una Agencia de Publicidad (como Contratante y sujeto
              también a estos TCUS), campañas publicitarias con contenido
              promocionado en BRANDME.
            </li>
          </ol>
        </li>
        <li class="pb-lg text-left">
          Panel de Control Independiente y Exclusivo.-
          <p class="pt-lg text-justify">
            BRANDME podrá facilitar a las Agencias de Publicidad que lo
            soliciten un Acceso Exclusivo mediante un Panel de Control
            dentro de sus cuentas como Anunciante en
            <a
              class="color-purple"
              href="https://app.brandme.la/"
              target="_blank"
              rel="noopener noreferrer"
              >www.brandme.la</a
            >
            que podrá ser personalizado y adaptado cuanto sea necesario, con
            el acuerdo previo con BRANDME. Mediante este Panel de Control
            podrán gestionar todas las campañas publicitarias de sus
            Anunciantes.
          </p>
          <p class="text-justify">
            Simultáneamente, los Anunciantes estarán disponibles en el Panel
            General de
            <a
              class="color-purple"
              href="https://app.brandme.la/"
              target="_blank"
              rel="noopener noreferrer"
              >www.brandme.la</a
            >
            y, por tanto, cualquier gestor de cuentas que legalmente le
            represente podrá proponer campañas publicitarias a dichos
            anunciantes conforme a estos TCUS. Simultáneamente, dichas
            cuentas estarán disponibles en el Panel General de
            <a
              class="color-purple"
              href="https://app.brandme.la/"
              target="_blank"
              rel="noopener noreferrer"
              >www.brandme.la</a
            >
            y, por tanto, cualquier Anunciante o marca podrá proponer
            campañas publicitarias a dichas cuentas conforme a estos TCUS.
          </p>
        </li>
        <li class="pb-lg text-left">
          Elegibilidad de los Creadores.-
          <ol class="lower-roman pt-lg pl-default ml-2">
            <li class="pb-lg">
              ) A fin de poder participar en EL SISTEMA, el Usuario con
              calidad de Creador, al realizar su Registro en BRANDME, deberá
              registrar por lo menos dos cuentas de Redes Sociales de las
              que prevea en EL SISTEMA BRANDME, y deberá cumplir con los
              requerimientos mínimos de elegibilidad como Creador. BRANDME
              podrá rechazar la participación de ciertos Creadores a su sola
              discreción.
              <p class="pt-lg text-justify">
                El Creador podrá definir libremente los datos de
                identificación de su perfil, el tipo de anunciantes o marcas
                sobre las cuales desearían hacer publicidad, seleccionando
                las categorías que establezca para ello BRANDME en EL
                SISTEMA. En todo momento podrá a través de EL SISTEMA
                cambiar el Creador sus preferencias de Campañas o su
                información de Registro.
              </p>
            </li>
            <li class="pb-lg">
              ) BRANDME es autorizado por los Usuarios para poder clasificar
              a su sola discreción a los Usuarios que tengan la calidad de
              Creadores. Para efectos de estos TCUS, los Creadores son
              clasificados en cuatro clases: i) Creadores, ii) Creadores
              Pro, iii) Creadores Gold y iv) Creadores VIP (Celebridades).
              Todos y cada uno de los anteriores aceptan apegarse a estos
              TCUS, sin embargo, las Celebridades, deberán apegarse también
              a los Términos y condiciones específicos que se establecen
              dentro de estos TCUS aplicables para ellos.
            </li>
            <li class="pb-lg">
              ) El Creador declara, garantiza y acuerda que la información
              proporcionada por él es real y que se mantendrá actualizada
              para que se mantenga al corriente y precisa en todo momento.
            </li>
          </ol>
        </li>
        <li class="pb-lg text-left">
          Métricas e Información de Desempeño.-
          <p class="pt-lg text-justify">
            BRANDME constantemente busca la manera más adecuada y precisa de
            estimar, calcular y medir el impacto de las publicaciones en la
            audiencia conformada por los Usuarios en las distintas redes
            sociales; sin embargo, el Anunciante acepta que toda la
            información que BRANDME muestre, a través de EL SISTEMA, puede
            verse afectada por factores ajenos al control de BRANDME, por
            esta razón el Anunciante libera a BRANDME de toda
            responsabilidad sobre los posibles errores, imprecisiones,
            fallas u omisiones en la información mostrada en la Plataforma,
            referente al desempeño de la Campaña, incluyendo clicks,
            impactos, retweets, replies, o cualquier otra información de
            desempeño.
          </p>
        </li>
      </ol>
      <p class="text-justify">
        <strong
          >5.- Código de conducta y términos de acceso y uso del
          SITIO.</strong
        >
      </p>
      <ol class="lower-alpha pl-default">
        <li class="pb-lg text-left">
          Usuarios que pueden usar EL SISTEMA.-
          <p class="pt-lg text-justify">
            Todos los Usuarios del SITIO deben ser personas con capacidad
            jurídica que hayan cumplido los 18 años de edad. Sólo pueden
            registrarse en EL SISTEMA de BRANDME, personas físicas o
            sociedades legalmente constituidas con capacidad para celebrar
            transacciones legales a través de su representante legal
            debidamente acreditado.
          </p>
          <p class="pt-lg text-justify">
            El correcto comportamiento de los Usuarios es esencial para el
            buen funcionamiento del servicio. A continuación se detalla el
            código de conducta que éstos deberán tener para acceder y
            utilizar el SITIO, indicándose expresamente que el cumplimiento
            de lo descrito a continuación es condición esencial para la
            utilización del SITIO.
          </p>
        </li>
        <li class="pb-lg text-left">
          Código de Conducta de Usuarios.-
          <ol class="lower-roman pt-lg pl-default ml-2">
            <li class="pb-lg">
              ) Los Usuarios declaran bajo su exclusiva responsabilidad que
              van a actuar cumpliendo con la normativa aplicable, de forma
              responsable, respetuosa, diligente y con total buena fe.
            </li>
            <li class="pb-lg">
              ) En especial, el Usuario garantiza que los servicios que
              ofrece y que puede ser objeto de acuerdo con otros Usuarios no
              son ilegales, inmorales, prohibidos ni atentan o infringen
              derechos de terceros, y que cuenta con todas las
              autorizaciones y demás requisitos exigidos por el ordenamiento
              jurídico para desarrollar la actividad contratada.
            </li>
            <li class="pb-lg">
              ) De igual modo el Usuario se compromete, bajo su sola
              responsabilidad, a suministrar información completa, exacta y
              veraz sobre su actividad empresarial y sobre los servicios que
              ofrece a través del SITIO así como, en su caso, sobre sus
              experiencias en relación a las transacciones realizadas con el
              resto de Usuarios.
            </li>
            <li class="pb-lg">
              ) El Usuario tiene expresamente prohibido las siguientes
              conductas que se describen a continuación:
              <ul class="pt-lg ml-3">
                <li class="pb-lg">
                  Crear identidades falsas, actuar en nombre de terceros sin
                  el poder o legitimación suficiente y/o suplantar la
                  identidad de otros.
                </li>
                <li class="pb-lg">
                  Introducir datos falsos y/u ofertas ficticias, debiendo
                  ser todas sus ofertas totalmente exactas y veraces.
                </li>
                <li class="pb-lg">
                  Infringir derechos de terceros, en especial, derechos de
                  propiedad industrial o intelectual.
                </li>
                <li class="pb-lg">
                  Faltar a la verdad, falsificar documentos, firmas,
                  infringir las leyes, reglamentos o normativa de aplicación
                </li>
                <li class="pb-lg">
                  Introducir ofertas sobre cosas u objetos que estén fuera
                  del comercio.
                </li>
                <li class="pb-lg">
                  Registrarse y utilizar el SITIO sin tener la capacidad
                  suficiente para ello.
                </li>
                <li class="pb-lg">
                  Manipular o alterar las herramientas del SITIO para
                  comercializar con terceros u otros Usuarios en forma
                  distinta a la indicada en los TCUS.
                </li>
                <li class="pb-lg">
                  No respetar las normas y procesos predeterminados para el
                  acceso y uso del SITIO, emplear métodos que puedan causar
                  daños al SITIO o a los sistemas de terceros.
                </li>
                <li class="pb-lg">
                  Utilizar EL SITIO para fines distintos a los que le son
                  propios.
                </li>
                <li class="pb-lg">
                  Contactar con otros Usuarios del SITIO así como disponer
                  de los datos facilitados por éstos para finalidades
                  distintas de las establecidas en los TCUS y en las
                  particulares que puedan regular el acceso a servicios
                  ofrecidos a través del SITIO.
                </li>
                <li class="pb-lg">
                  Enviar cartas en cadena y/o publicidad no solicitada, a
                  menos que cuente con el consentimiento expreso del
                  destinatario.
                </li>
                <li class="pb-lg">
                  El Usuario acepta no transmitir a través del SITIO
                  información ni comercializar con material, productos o
                  servicios de ningún tipo o naturaleza que sea ilegal,
                  injurioso, difamatorio, dañino, vulgar, obsceno o en
                  cualquier otro modo censurable.
                </li>
                <li class="pb-lg">
                  En el supuesto que el Usuario tenga acceso a información
                  confidencial se compromete a utilizarla exclusivamente
                  para las finalidades para las que se le fueron reveladas,
                  siempre respetando dicha confidencialidad.
                </li>
                <li class="pb-lg">
                  BRANDME, según su propio criterio, podrá dar por terminado
                  el acceso al SITIO, de forma inmediata, y sin necesidad de
                  comunicación previa, si la conducta del Usuario no fuera
                  acorde con los presentes TCUS.
                </li>
              </ul>
            </li>
            <li class="pb-lg">
              ) Intercambio de información de contacto.
              <p class="pt-lg text-justify">
                BRANDME podrá proveer, a todos los Usuarios, una plataforma
                que incluya mensajería privada necesaria y suficiente para
                la negociación de cualquier proyecto. El Creador se
                compromete a informar inmediatamente a BRANDME si un
                Contratante o Anunciante le pretenda realizar pagos por
                fuera de EL SISTEMA de BRANDME.
              </p>
              <p class="pt-lg text-justify">
                En caso de que el Anunciante o Contratante intente hacer
                transacciones fuera de la plataforma, pierde todas las
                garantías que BRANDME provee y se cerrara su cuenta de
                manera permanente sin aviso previo y sin responsabilidad
                para BRANDME.
              </p>
            </li>
          </ol>
          <p class="pt-lg text-justify">
            BRANDME podrá facilitar a las Agencias de Publicidad que lo
            soliciten un Acceso Exclusivo mediante un Panel de Control
            dentro de sus cuentas como Anunciante en
            <a
              class="color-purple"
              href="https://app.brandme.la/"
              target="_blank"
              rel="noopener noreferrer"
              >www.brandme.la</a
            >
            que podrá ser personalizado y adaptado cuanto sea necesario, con
            el acuerdo previo con BRANDME. Mediante este Panel de Control
            podrán gestionar todas las campañas publicitarias de sus
            Anunciantes.
          </p>
          <p class="text-justify">
            Simultáneamente, los Anunciantes estarán disponibles en el Panel
            General de
            <a
              class="color-purple"
              href="https://app.brandme.la/"
              target="_blank"
              rel="noopener noreferrer"
              >www.brandme.la</a
            >
            y, por tanto, cualquier gestor de cuentas que legalmente le
            represente podrá proponer campañas publicitarias a dichos
            anunciantes conforme a estos TCUS. Simultáneamente, dichas
            cuentas estarán disponibles en el Panel General de
            <a
              class="color-purple"
              href="https://app.brandme.la/"
              target="_blank"
              rel="noopener noreferrer"
              >www.brandme.la</a
            >
            y, por tanto, cualquier Anunciante o marca podrá proponer
            campañas publicitarias a dichas cuentas conforme a estos TCUS.
          </p>
        </li>
        <li class="pb-lg text-left">
          Los Usuarios reconocen que la obligación de compensar por la
          participación de un Creador en alguna Campaña, se hará a través de
          EL SISTEMA. Para ello, será necesario que el Anunciante cuente con
          fondos suficientes debidamente depositados en EL SISTEMA. La falta
          de estos fondos, libera a BRANDME de cualquier responsabilidad con
          el arranque o seguimiento de cualquier Campaña.
        </li>
        <li class="pb-lg text-left">
          En razón de lo anterior, BRANDME mantendrá disponible para el
          Anunciante los mecanismos dentro de EL SISTEMA para que puedan
          transferir fondos para la operación de sus Campañas, ya sea a
          través de vinculación de cuentas bancarias, tarjetas de crédito o
          débito, cuentas de pago en línea, o cualquier otro mecanismo que
          permita la transferencia de dinero, presentes y futuras. BRANDME
          se obliga a resguardar esta información sensible conforme a lo
          señalado en el Aviso de Privacidad que tiene publicado en el
          SITIO.
        </li>
        <li class="pb-lg text-left">
          Los pagos que realice BRANDME a favor de los Creadores serán en
          cumplimiento de una Comisión Mercantil que mediante estos TCUS y
          cualquier otro contrato, otorga el Anunciante a BRANDME, por lo
          que no se entenderá en ningún momento que exista una relación
          laboral entre BRANDME y cualquiera de los Usuarios. Dichos pagos
          siempre deberá realizarse por instrucción expresa que realice el
          Anunciante conforme a los procesos que establezca EL SISTEMA.
        </li>
        <li class="pb-lg text-left">
          El Creador reconoce y acepta que:
          <ol class="lower-roman pt-lg pl-default ml-1">
            <li class="pb-lg">
              ) No presta un servicio personal subordinado a BRANDME ni a
              otros Usuarios.
            </li>
            <li class="pb-lg">
              ) No es un empleado de BRANDME ni de otros Usuarios.
            </li>
            <li class="pb-lg">
              ) Es responsable del pago de cualquier impuesto, federal,
              estatal o municipal, causado por o relacionado con el ingreso
              que perciba por participar en el Servicio;
            </li>
            <li class="pb-lg">
              ) Deberá entregar una factura o comprobante fiscal autorizado,
              con desglose de Impuesto al Valor Agregado, por la prestación
              de su servicio y por el monto de su compensación cuando se
              superen los $1,000.00 (un mil pesos) mensuales;
            </li>
            <li class="pb-lg">
              ) Para el Creador VIP (Celebridad) sin importar el monto
              deberá entregar factura o comprobante fiscal autorizado, con
              desglose de Impuesto al Valor Agregado, por la prestación de
              su servicio y por el monto de su compensación.
            </li>
            <li class="pb-lg">
              ) Que cuenta con tres (3) meses para hacer llegar dichas
              facturas a BRANDME; de lo contrario, libera a BRANDME de la
              obligación y responsabilidad de efectuar la compensación
              correspondiente.
            </li>
            <li class="pb-lg">
              ) Es responsable de reportar cualquier información requerida
              por el gobierno, ya sea federal, estatal o municipal;
            </li>
            <li class="pb-lg">
              ) Es responsable de cumplir con cualquier ley o regulación
              aplicable.
            </li>
          </ol>
        </li>
        <li class="pb-lg text-left">
          El Creador reconoce y acepta que es su responsabilidad, y de nadie
          más, el proporcionar su información bancaria personal, correcta,
          verdadera y necesaria para que BRANDME realice la transferencia
          electrónica bancaria correspondiente al pago de las compensaciones
          generadas por el Creador en caso de que realice publicaciones a
          través de EL SISTEMA. El Creador afirma que en ningún momento
          proporcionará la información bancaria de otra persona o entidad, y
          que de hacerlo acepta que BRANDME dé por terminada su cuenta y sus
          ganancias no le sean entregadas. De esta manera el Creador acepta
          y da permiso a BRANDME de realizar toda investigación legal
          necesaria para verificar la veracidad de su información. Asimismo,
          el Creador acepta que sus ganancias no sean entregadas en caso de
          que BRANDME deje de ofrecer el Servicio por incumplimiento a estos
          TCUS o a cualquier ley aplicable. BRANDME se obliga a resguardar
          esta información sensible conforme a lo señalado en el Aviso de
          Privacidad que tiene publicado en el SITIO.
        </li>
        <li class="pb-lg text-left">
          Precios y forma de pago
          <p class="pt-lg text-text-left">
            BRANDME da la oportunidad a Creadores de vender contenido de sus
            redes por medio de EL SISTEMA, en donde el pago depende de cada
            contenido el cual tanto Anunciante como Creador lo establecen al
            llegar a un acuerdo en donde dicho pago se verá reflejado 30
            días naturales después de la publicación del contenido
            patrocinado y se encuentre verificado, al mismo tiempo el
            Creador podrá solicitar el retiro de sus ganancias en cualquier
            momento en donde según el caso y el Plan en donde algunos casos
            de cobra un fee por pronto retiro &gt;
          </p>
          <p class="pt-lg text-justify">
            El Creador reconoce y acepta que BRANDME se reserva, en todo
            momento, el derecho a determinar, fijar o modificar, a su
            discreción, la compensación a pagar al Creador por las
            publicaciones que envíe a través de EL SISTEMA haciendo uso del
            Servicio. El Creador reconoce y acepta que BRANDME podrá
            emplear, a su discreción, cualquier medio que considere
            necesario para calcular la compensación por publicación, y que
            incluso podrá determinarlo arbitrariamente.
          </p>
          <p class="pt-lg text-justify">
            Las campañas publicitarias se cobran online a través de Paypal,
            Stripe o empresas similares así como transferencias bancarias.
            Los datos facilitados por los Usuarios a tal fin serán tratados
            de forma confidencial conforme al Aviso de Privacidad disponible
            en el SITIO.
          </p>
          <p>
            El Creador puede acumular los pagos de sus participaciones
            dentro de la plataforma, aceptando que dicho monto tendrá un
            plazo de 2 años para ser retirado
          </p>
          <p class="pt-lg text-justify">
            BRANDME emitirá liquidaciones mensuales que incluirán todos los
            ingresos efectivamente percibidos y el porcentaje que
            corresponda a los Creadores. Los pagos se realizarán mediante
            transferencia bancaria o Paypal (o método de pago similar). A
            tal efecto, los Creadores deberán facilitar a BRANDME los datos
            de una cuenta corriente activa. Los impuestos y cargas serán
            conforme a lo dispuesto por la legislación vigente y los
            Anunciantes e Creadores, serán responsables del cumplimiento de
            las obligaciones de carácter laboral, civil, mercantil, fiscal y
            del pago de la Seguridad Social de su propio personal que asigne
            para el desempeño de sus actividades, salvo lo establecido
            dentro de estos TCUS.
          </p>
        </li>
        <li class="pb-lg text-left">
          Otorgamiento de Licencia.- El Creador otorga a BRANDME, y al
          Anunciante específico que configura la Campaña relacionada con la
          publicación, una licencia mundial, sin derecho a regalías,
          sub-licenciable, incondicional e intransferible para usar,
          desplegar, mostrar, reproducir, republicar y distribuir el
          contenido o una porción de éste a través de cualquier tipo de
          medio y a través de cualquier canal, presente o futuro,
          incluyendo, de forma no limitativa, televisión, radio, impresos,
          sitios de Internet y otras comunicaciones electrónicas.
        </li>
        <li class="pb-lg text-left">
          Creadores VIP (Celebridades con cuenta verificada)
        </li>
      </ol>
      <p class="text-justify">
        BRANDME podrá otorgar esta calidad a cualquier Creador, después de
        determinar que su peso específico en alguna campaña o en varias
        campañas puede representar un beneficio mayor a el Anunciante.
        BRANDME utilizará sus propios algoritmos, los de otras plataformas,
        comentarios en redes sociales, o incluso en medios de comunicación,
        para determinar el otorgamiento de esta calidad. Dado que este
        Creador VIP recibirá mayor compensación por sus servicios, en todo
        caso, y como se ha establecido en estos TCUS, siempre deberá
        entregar factura o comprobante fiscal para recibir sus pagos. Esta
        calidad en todo momento es discrecional a favor de BRANDME, y no
        genera ningún derecho de antigüedad o preferencia a favor de los
        Creadores, por lo que puede ser otorgada o revocada en cualquier
        momento por BRANDME. Este tipo de Creador estará sometido a todas
        las obligaciones que tengan todos los Creadores dentro de estos
        TCUS.
      </p>
      <p class="text-justify">
        <strong>6.- Enlaces entre páginas Web</strong>
      </p>
      <p class="text-justify">
        6.1.- Aquellos Usuarios que pretendan establecer un hiperenlace
        entre su página web y el SITIO (en adelante, el hiperenlace) deberán
        contar con autorización expresa de BRANDME y cumplir con las
        condiciones siguientes:
      </p>
      <ol class="lower-alpha pl-default ml-1">
        <li class="pb-lg text-left">
          El hiperenlace únicamente permitirá el acceso a la home del SITIO,
          pero no podrá reproducirla de ninguna forma.
        </li>
        <li class="pb-lg text-left">
          No se creará un frame sobre las páginas del SITIO.
        </li>
        <li class="pb-lg text-left">
          No se realizarán manifestaciones o indicaciones falsas, inexactas
          o incorrectas sobre BRANDME, sus directivos, sus empleados y los
          servicios suministrados a través del SITIO.
        </li>
        <li class="pb-lg text-left">
          No se declarará ni se dará a entender que BRANDME ha autorizado el
          hiperenlace o que ha supervisado o asumido de cualquier forma los
          servicios ofrecidos o puestos a disposición desde la página web en
          la que se establece el hiperenlace.
        </li>
        <li class="pb-lg text-left">
          Excepción hecha de aquellos signos que formen parte del mismo
          hiperenlace, la página web en la que se establezca el hiperenlace
          no contendrá ninguna marca, nombre comercial, rótulo de
          establecimiento, denominación, logotipo, eslogan u otros signos
          distintivos pertenecientes a BRANDME.
        </li>
        <li class="pb-lg text-left">
          La página web en la que se establezca el hiperenlace no contendrá
          informaciones o contenidos ilícitos, contrarios a la moral y a las
          buenas costumbres generalmente aceptadas y al orden público, así
          como contenidos contrarios a cualesquiera derechos de terceros.
        </li>
      </ol>
      <p class="text-justify">
        El establecimiento del hiperenlace (link) no implica, en ningún
        caso, la existencia de relaciones entre BRANDME y el propietario de
        la página web en la que se establezca, ni la aceptación y aprobación
        por parte de BRANDME de sus contenidos o servicios.
      </p>
      <p class="text-justify">
        6.2.- BRANDME pone a disposición de los Usuarios enlaces o links con
        el fin de facilitar el acceso a la información, servicios y demás
        contenidos disponibles en Internet. Los enlaces o links habilitados
        en este SITIO pueden conducir al Usuario a otros sitios y páginas
        web gestionadas por terceros, sobre los que BRANDME no ejerce ningún
        tipo de control.
      </p>
      <p class="text-justify">
        BRANDME no asume ningún deber de vigilar ni de verificar las
        informaciones o contenidos de los otros sitios o páginas web a los
        que se pueda acceder mediante los hiperenlaces existentes en el
        SITIO. BRANDME asume exclusivamente, el deber de suprimir del SITIO,
        con la mayor brevedad posible, las informaciones, contenidos o
        servicios que no correspondan con la realidad, atenten contra la
        moral, las buenas costumbres, cualquier normativa aplicable o que
        puedan inducir a error o causar daño al Usuario.
      </p>
      <p class="text-justify">
        <strong>7.- Propiedad Industrial e Intelectual</strong>
      </p>
      <p class="text-justify">
        7.1. – El Usuario reconoce expresamente, a todos los efectos, que la
        información, signos, imágenes, diseños, logotipos, animaciones,
        vídeos, textos, así como los demás elementos y contenidos, incluidos
        sus servicios y aplicaciones informáticas, del SITIO están sujetos a
        derechos de propiedad industrial e intelectual de BRANDME y/o de
        terceros titulares de los mismos que han autorizado debidamente su
        inclusión en el SITIO , por lo que no se les concede ningún tipo de
        derecho sobre ellos, salvo los que expresamente se reconozcan.
      </p>
      <p class="text-justify">
        7.2. –.Los Usuarios que accedan al SITIO pueden visualizar la
        información contenida en el mismo y efectuar descargas o
        reproducciones privadas a su sistema informático, siempre que los
        elementos reproducidos no sean cedidos posteriormente a terceros o
        se instalen en un servidor conectado a Internet o a una red local.
      </p>
      <p class="text-justify">
        7.3. – En consecuencia con lo dispuesto en el apartado anterior, el
        Usuario no estará facultado para, a título meramente enunciativo,
        distribuir, modificar, transformar, ceder poner a disposición del
        público o llevar a cabo cualquier otra actividad que no haya sido
        expresamente autorizada sobre los elementos y contenidos indicados
        anteriormente, incluidos los códigos informáticos de cualquiera de
        los elementos del SITIO.
      </p>
      <p class="text-justify">
        7.4. – El Usuario será directamente responsable de todas las
        consecuencias, daños y perjuicios que se deriven para BRANDME y/o
        terceros de la infracción de cualquier obligación contenida en la
        presente cláusula.
      </p>
      <p class="text-justify">
        7.5. – El Usuario que proceda al envío de cualquier tipo de
        contenido al SITIO, asegura que ostenta los derechos necesarios para
        hacerlo, quedando exento BRANDME de cualquier responsabilidad sobre
        el contenido y legalidad de la información ofrecida.
      </p>
      <p class="text-justify">
        7.6. – La facilitación de contenidos por parte del Usuario a través
        del SITIO, supondrá la cesión a BRANDME con el carácter definido en
        estos TCUS de los derechos de explotación de propiedad intelectual e
        industrial de derivados de tales contenidos, con la máxima amplitud
        permitida por la legislación aplicable y conforme con estos TCUS.
      </p>
      <p class="text-justify">
        7.7. – El Usuario reconoce a BRANDME el derecho de modificar los
        contenidos introducidos a fin de que se adecuen y sean compatibles
        con las características técnicas y estéticas del SITIO.
      </p>
      <p class="text-justify">
        7.8. – Restricciones de ingeniería inversa, descompilación y
        desensamblaje. EL Usuario no podrá utilizar técnicas de ingeniería
        inversa, descompilar ni desensamblar EL SISTEMA, sus programas y
        componentes. EL SISTEMA se otorga bajo licencia como un producto
        único e indivisible. Sus partes y componentes no pueden separarse
        para ser usadas en forma distinta a lo que este contrato dispone.
        Fuera del uso de la API de EL SISTEMA que sea autorizado por BRANDME
        a algún Usuario para efecto de crear otro Mercado para Anunciantes,
        cualquier intento mediante el uso de programas automatizados,
        robots, o cualquier otro medio de recopilación de datos, contenido u
        otra información con el fin de violentar la integridad de EL SISTEMA
        o de sus componentes queda estrictamente prohibido. Cualquier
        manipulación de datos, código o de cualquier otro dato relevante o
        métrica utilizada y presente dentro de EL SISTEMA y sus Componentes
        de igual forma queda estrictamente prohibido. Cualquier intento no
        autorizado de cualquiera de estas actividades se perseguirá conforme
        a las leyes civiles y penales aplicables.
      </p>
      <p class="text-justify"><strong>8.- Responsabilidad</strong></p>
      <p class="text-justify">
        8.1. – Sin perjuicio de las limitaciones establecidas en la
        legislación vigente, BRANDME no se responsabiliza por los daños y
        perjuicios de toda naturaleza que puedan deberse a la falta de
        exactitud, exhaustividad, actualidad, así como a errores u omisiones
        de los que pudieran adolecer las informaciones contenidas en el
        SITIO u otros contenidos a los que se pueda acceder a través del
        mismo.
      </p>
      <p class="text-justify">
        8.2. – BRANDME no se hace responsable por los daños y perjuicios
        ocasionados por el uso indebido del servicio por parte de los
        Usuarios. Los Usuarios deberán abstenerse de realizar actos de
        cualquier tipo que contravengan la legislación aplicable.
      </p>
      <p class="text-justify">
        8.3. – BRANDME no estará obligada a reparar los daños o perjuicios,
        directos o indirectos, que sean ocasionados por una información
        incompleta o incorrecta y no veraz, suministrada por los Usuarios
        del SITIO, así como tampoco se hace responsable del comportamiento
        de los Usuarios, de sus posibles incumplimientos y/o de las posibles
        situaciones de insolvencia en las que puedan encontrarse.
      </p>
      <p class="text-justify">
        8.4. – BRANDME no estará obligada a reparar los daños o perjuicios,
        directos o indirectos, que sean consecuencia de servicios prestados
        por proveedores externos con las que BRANDME haya alcanzado acuerdos
        de colaboración o comerciales, en las que actúa únicamente como mero
        intermediario y que sean ajenos a la actividad propia del SITIO
        entre Usuarios registrados.
      </p>
      <p class="text-justify">
        8.5. – BRANDME no garantiza la disponibilidad, continuidad ni la
        infalibilidad del funcionamiento del SITIO y en, consecuencia,
        excluye, en la máxima medida permitida por la legislación vigente,
        cualquier responsabilidad por los daños y perjuicios de toda
        naturaleza que puedan deberse a la falta de disponibilidad o de
        continuidad del funcionamiento del SITIO y de los servicios
        habilitados en el mismo, así como a los errores en el acceso a las
        distintas páginas web o a aquellas desde las que, en su caso, se
        presten dichos servicios.
      </p>
      <p class="text-justify">
        8.6. – BRANDME declina toda responsabilidad por los daños
        informáticos o de otro tipo que pudiera ocasionar al Usuario
        visitante en el acceso a los contenidos del SITIO. Por ello, BRANDME
        no garantiza la ausencia de virus u otros elementos lesivos que
        pudieran causar daños o alteraciones en el sistema informático, en
        los documentos electrónicos o en los ficheros del Usuario o de
        terceros.
      </p>
      <p class="text-justify">
        8.7. – En ningún caso será responsable BRANDME de los daños que
        pueda sufrir el Usuario en caso de que incumpla los presentes TCUS.
      </p>
      <p class="text-justify">
        8.8. – El Usuario será el único responsable en relación con su
        actividad dentro de EL SISTEMA, tanto en lo relativo a la gestión,
        como en relación con el cumplimiento de la normativa vigente en todo
        lo que le resulte de aplicación en atención al tipo de actividad que
        desarrolla. A estos efectos, se entenderá por gestión, todas las
        actividades relacionadas con la prestación del Servicio, así como
        cualquier otra actividad relacionada con el modelo de transacción
        que lleve a cabo a través del SITIO.
      </p>
      <p class="text-justify">
        8.9. – El Usuario será único y exclusivo responsable de los daños y
        perjuicios que se ocasionaren a BRANDME, a cualquier Usuario y/o
        tercero, que tengan causa en la mala fe, ilicitud, falsedad,
        inexactitud, falta de diligencia, incumplimiento de cualquier
        información, contenido y/o del objeto de cualquier transacción,
        compromisos o acuerdos que asuma con otro Usuario o Usuario a raíz
        de la utilización del SITIO o que se derive de la misma.
      </p>
      <p class="text-justify">
        8.10. – Si BRANDME fuera declarada responsable de cualquier daño o
        perjuicio con causa a los anteriores extremos en un procedimiento
        judicial o extrajudicial, por un Juzgado, Tribunal o Ente Público
        administrativo competente, podrá repetir contra el Usuario todas las
        cantidades que haya tenido que abonar y soportar, incluido el daño
        emergente y el lucro cesante, y cualquier coste o gravamen que
        hubiera tenido que afrontar, incluidos impuestos, tasas, abogados,
        procuradores, peritos, arbitrajes, viajes, estancias, y cualquier
        otro.
      </p>
      <p class="text-justify">
        8.11. – BRANDME se reserva el derecho de comunicar la información
        confidencial que los Usuarios le hayan comunicado cuando venga
        impuesto por ley, reglamento, normativa, ordenanza y/o cualquier
        otro mandato legal, judicial o solicitud gubernamental.
      </p>
      <p class="text-justify">
        <strong>9.- Protección y funcionamiento del SITIO</strong>
      </p>
      <p class="text-justify">
        9.1. – BRANDME, mantendrá operativo el SITIO durante todo el año,
        las 24 horas del día, siempre que el estado de la técnica lo
        permita.
      </p>
      <p class="text-justify">
        9.2. – Asimismo, BRANDME se reserva el derecho de modificar o
        interrumpir, en todo o en parte, el acceso a EL SISTEMA
        temporalmente, cuando lo requiera el servicio de mantenimiento,
        actualización o reparación del sistema o el servidor de Internet,
        sin necesidad de haberlo notificado al Usuario.
      </p>
      <p class="text-justify">
        9.3. – Si el Usuario detectara alguna anomalía, interferencias de
        terceros, errores del SISTEMA o mal funcionamiento del SITIO, deberá
        notificarlo a BRANDME para que esta proceda a su solución.
      </p>
      <p class="text-justify"><strong>10.- Baja del servicio.</strong></p>
      <p class="text-justify">
        Para darse de baja de la plataforma, es necesario enviar un correo
        electrónico a la dirección
        <a
          class="color-purple"
          href="mailto:contacto@brandme.la"
          target="_blank"
          rel="noopener noreferrer"
          >contacto@brandme.la</a
        >, indicando los motivos por los que se quiere dejar de usar el
        servicio.
      </p>
      <p class="text-justify">
        <strong>11.- Cookies y otras tecnologías.</strong>
      </p>
      <p class="text-justify">
        Cuando usted visita sitios web en Internet, su navegador
        automáticamente puede transmitir información a los sitios web que
        visita durante cada visita. Al igual que muchos sitios web, podemos
        usar &#8220;cookies&#8221; para recoger información. Una cookie es
        un pequeño archivo de datos que transferimos al disco duro de su
        computadora para propósitos de registro. Podemos utilizar cookies
        persistentes que sólo
        <a
          class="color-purple"
          href="https://app.brandme.la/"
          target="_blank"
          rel="noopener noreferrer"
          >www.brandme.la</a
        >
        sabe leer y utilizar para guardar la información de inicio de sesión
        para las conexiones futuras con el Servicio, para ayudar en el
        procesamiento de información, para mantener la información de
        sesión, y para rastrear las preferencias del usuario. Podemos
        utilizar las cookies de identificación de sesión para habilitar
        determinadas características del Servicio, para entender mejor la
        forma de interactuar con el SITIO y para controlar el uso global de
        nuestros usuarios y el tráfico de enrutamiento en el Servicio, y
        para identificar las áreas visitadas del Sitio y Servicios. A
        diferencia de las cookies persistentes, las cookies de sesión se
        borran de su equipo cuando se cierre la sesión en el SITIO y cierra
        su navegador. Los anunciantes de terceros en el Sitio también puede
        colocar o leer cookies en su navegador y podemos utilizar las
        cookies de Flash (u objetos locales compartidos) para almacenar sus
        preferencias o el contenido de visualización basado en lo que usted
        ve en el SITIO para personalizar su visita. Puede indicar a su
        navegador, cambiando sus opciones, dejar de aceptar cookies o para
        que le avise antes de aceptar una cookie en los sitios web que
        visite. Si usted no acepta cookies, sin embargo, usted no será capaz
        de utilizar todas las partes o todas las funcionalidades del
        Servicio. Nosotros y nuestros proveedores de servicios también
        podemos utilizar &#8220;píxel tags&#8221;, &#8220;web
        beacons&#8221;, &#8220;clear GIFs&#8221;, o medios similares en
        relación con el Servicio y mensajes de correo electrónico con
        formato HTML, entre otras cosas, para dar seguimiento de las
        acciones de los usuarios y a través del correo electrónico de
        destinatarios, para determinar el éxito de las campañas de marketing
        y para compilar estadísticas agregadas acerca del uso del sitio y
        las tasas de respuesta. También pueden participar uno o más
        proveedores de servicios de terceros con anuncios en línea en EL
        SISTEMA. Podrán utilizar un &#8220;pixel tag&#8221; para recopilar
        información anónima sobre sus visitas a este SITIO y otros sitios
        web, y pueden utilizar esa información para determinar que anuncios
        sobre bienes y servicios pueden ser de su interés de acuerdo a su
        localización. Esta información se recoge de forma anónima, de una
        manera que no lo identifica personalmente.
      </p>
      <p class="text-justify">
        <strong>12.- Legislación Aplicable y Jurisdicción.</strong>
      </p>
      <p class="text-justify">
        El acceso y utilización del SITIO así como la interpretación,
        cumplimiento y ejecución de las presentes TCUS se sujetará a lo
        establecido por la legislación Mexicana, y en caso de controversia,
        el Usuario acepta someterse para la interpretación y cumplimiento de
        estos TCUS a lo establecido por el Código de Comercio Mexicano, así
        como a las reglas internacionales que al efecto ha establecido la
        Cámara de Comercio Internacional, así como la Convención sobre el
        Reconocimiento y la Ejecución de las Sentencias Arbitrales
        Extranjeras (Convención de Nueva York) de 1958, y se someten
        expresamente a la jurisdicción y competencia de un tribunal arbitral
        el cual se constituirá conforme a las reglas que para el efecto se
        establecen en los acuerdos internacionales respectivos, y en
        específico a lo señalado al respecto por las directrices
        establecidas al efecto por la Cámara Internacional de Comercio en
        cuanto a las reglas de arbitraje establecidas por esta, y acorde a
        lo siguiente:
      </p>
      <p class="text-justify">
        12.1. – Cualquier disputa será decidida por un panel arbitral
        integrado por tres árbitros.
      </p>
      <p class="text-justify">
        12.2. – Cada PARTE nombrará un árbitro y el tercero será nombrado
        por los dos árbitros nombrados por las partes y este Tercer Arbitro
        actuará como Presidente del Panel Arbitral.
      </p>
      <p class="text-justify">
        12.3. – Cualquier reclamo presentado por una parte, deberá
        realizarse conforme a las reglas y bajo las causales de rescisión
        establecidas dentro de estos TCUS y las reglas que al efecto se
        establezcan en las leyes aplicables.
      </p>
      <p class="text-justify">
        12.4. – El reclamante deberá notificar por escrito sobre su
        intención de someter la disputa a Arbitraje, de acuerdo con los
        términos establecidos para la comunicación entre LAS PARTES
        convenidas en estos TCUS y EL SISTEMA.
      </p>
      <p class="text-justify">
        12.5. – Las PARTES acuerdan que la ubicación para realizar el
        arbitraje queda a libre determinación de BRANDME, y los costos
        deberán pagarse por LA PARTE que inicie el arbitraje. En caso de
        obtener un Laudo favorable, deberá cubrir LA PARTE condenada el
        costo del proceso.
      </p>
      <p class="text-justify">
        12.6. – Solo se admitirán pruebas documentales y testimonios de
        peritos para establecer los hechos del caso.
      </p>
      <p class="text-justify">
        12.7. – El nombramiento de Árbitro solo podrá ser recusado en los
        primeros tres días siguientes al señalamiento por cada parte del
        nombramiento, y forzará a la otra parte a nombrar un nuevo Arbitro.
        Este derecho a LAS PARTES sólo podrá ejercerse en dos ocasiones. Si
        alguna PARTE no estuviera conforme con el tercer arbitro nombrado,
        se forzará por una sola ocasión a que los otros dos Árbitros señalen
        uno sustituto.
      </p>
      <p class="text-justify">
        12.8. – Se celebrará una audiencia para desahogar pruebas periciales
        que se presentaran para acreditar calidad, pesos o medidas en cuanto
        a las operaciones realizadas.
      </p>
      <p class="text-justify">
        12.9. – El Laudo deberá ser emitido en dos semanas después de
        iniciado el proceso.
      </p>
      <p class="text-justify">
        12.10. – LAS PARTES acuerdan en que no será posible impugnar el
        Laudo que se emita.
      </p>
      <p class="text-justify">
        12.11. – LAS PARTES acuerdan en que el Laudo será plenamente
        ejecutable en los territorios de los países que residan o trabajen
        para efectos de este contrato, y que tendrá la calidad de convenio
        de transacción.
      </p>
      <p class="text-justify">12.12. – El Arbitraje será en español.</p>
      <p>&nbsp;</p>
      <p class="text-left">
        <strong
          >13.- Ley para la Transparencia en Publicidad dentro de
          México</strong
        >
      </p>
      <p class="text-justify">
        Todos los usuarios que se registren como &#8221; Creadores de
        Contenido &#8221; o &#8220;Influencers&#8221; al aceptar estos
        términos y condiciones aceptan y autorizan que &#8221; BRANDME
        &#8221; figure como su tutor legal ( mandato ) mientras dure la
        colaboración entre una marca que lo patrociné y su colaboración en
        donde dentro de la plataforma de &#8221; BRANDME &#8221; tanto la
        marca como la marca tendrá la información del registro del monto de
        la colaboración, la fecha de la misma y el histórico del contenido
        de colaboración mutua.
        En caso de registrarte como Creador y participar en al menos una campaña,
        BrandMe podrá utilizar el contenido para generar piezas de marketing dirigidas 
        en medios externos e internos a Marcas beneficiándote al impulsarte como Creador
        en distintas industrias con tomadores de decisión de marcas.
      </p>
    </b-card>
    <b-button
      v-if="show_button && !getAcceptTC()"
      :disabled="clicked"
      @click="$emit('redirect')"
      variant="primary"
      size="lg"
      class="button mr-2"
      >Aceptar los términos y condiciones</b-button
    >
  </b-card>
</template>

<script>
import { BCard, BCardText, BCardTitle, BButton } from "bootstrap-vue";

export default {
  name: 'TextTermsAndcondition',
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BButton
  },
  props: {
    show_button: {
      type: Boolean,
      default: true
    },
    clicked: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
    }
  },
  methods: {
    getAcceptTC() {
      if (!this.show_button) return false
      return localStorage.accept_tyc !== 'false';
    },
  },
};
</script>

<style>
.text {
  font-family: "Montserrat";
}
h3 {
  font-size: 20px;
}
.card-terms {
  height: 450px;
}
.head-text {
  font-size: 24px;
}

.subheader {
  margin-top: -10px;
  font-size: 20px;
}
.button {
  display: block;
  float: right;
}

.pointer-events-none {
  pointer-events: none !important;
}

.link {
  color: #7367f0 !important;
}

.disabled-item {
  color: #e2e2e2;
  opacity: 0.3;
}

@media (max-width: 1345px) {
  .head-text {
    font-size: 35px;
  }
}

@media (max-width: 350px) {
  .subheader {
    font-size: 20px;
  }
}
</style>
