<template>
  <b-modal
    body-class="padding-modal"
    centered
    hide-footer
    hide-header
    v-model="show"
    :size="size"
    @hidden="$router.push({name: 'dashboard-analytics'}); $emit('close')"
  >
    <div :class="`header-modal ${padding}`">
      <feather-icon
        icon="XIcon"
        size="24"
        :class="closeIcon"
        @click="show = !show"
      />
      <h4>{{ $t('startBM') }}</h4>         
    </div>
    <div :class="bodyClass">
      <slot name="body"></slot>
    </div>
  </b-modal>
</template>
  
<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "modalRedirect",
  components: {
    BModal,
  },
  data() {
    return {
      show: this.show_modal,
    };
  },
  props: {
    show_modal: {
      type: Boolean,
      required: true,
    },
    padding: {
      type: String,
      default: "p-2",
    },
    size: {
      type: String,
      default: "md",
    },
    closeIcon: {
      type: String,
      default: "close-modal",
    },
    bodyClass: {
      type: String,
      default: "p-1",
    },
  },
};
</script>
<style>
.btn-redirect {
  background: transparent;
  border-color: #ced4dabe;
  padding: 1.5em;
  margin-bottom: 1em;
  color: #212529 !important;
  font-weight: 100;
}
.btn-redirect:hover {
  background: #e9ecef5a;;
}
.btn-redirect:disabled:hover {
  background: none;
}
.padding-modal {
  padding: 0px !important;
}
.close-modal {
  display: block;
  color: rgb(155, 155, 155);
  position: absolute;
  right: 20px;
  top: 18px
}
.close-modal:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.header-modal {
  padding: 1em;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>